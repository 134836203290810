import _ from "lodash";

/**
 * Validation mixin
 * Provide easy way to dispatch errors from vuelidate and customize
 * error messages
 */
export default {
  data: function () {
    return {
      validator: {
        //List of validation errors
        errors: {},
        watchers: {},
      },
      attributeLabels: {},
    };
  },
  methods: {
    // Validation method
    // Start validation in according with component validation property
    // Normalize error & prepare error messages
    validate: function () {
      //init validation
      this.$v.$touch();
      //Clear dynamic watches created by validator
      _.each(this.validator.watchers, function (unwatch) {
        unwatch();
      });
      this.validator.errors = this.dispatchValidators(this.$v);
      return !this.$v.$invalid;
    },
    dispatchValidators(validator, keys = []) {
      //Remember component out of context
      let component = this;
      let errors = {};
      //Get list of attributes from validator
      let validationAttributes = _.keys(validator.$params);
      _.each(validationAttributes, function (attribute) {
        //get nested validator
        let attributeErrors = [];
        let nestedValidator = _.get(validator, [attribute]);
        //For each validator attribute check its rules
        if (
          _.isObject(nestedValidator.$model) &&
          !(nestedValidator.$model instanceof Array)
        ) {
          //If validator has nested attributes
          //dispatch recursively
          _.merge(
            errors,
            component.dispatchValidators(
              nestedValidator,
              _.concat(keys, [attribute])
            )
          );
        } else {
          if (!_.isEmpty(nestedValidator) && nestedValidator.$error) {
            //For each attribute rule
            //check & add message if it has errors
            _.each(nestedValidator.$params, function (rule, validatorName) {
              if (!nestedValidator[validatorName]) {
                attributeErrors.push(component.translateError(attribute, rule));
              }
            });
            let attributePath = _.concat(keys, attribute).join(".");
            _.set(errors, attributePath, attributeErrors);
            //Add watcher to clear errors on change
            component.validator.watchers[attributePath] = component.$watch(
              attributePath,
              function () {
                let errors = JSON.parse(
                  JSON.stringify(component.validator.errors)
                );
                _.set(errors, attributePath, []);
                component.validator.errors = errors;
              }
            );
          }
        }
      });
      return errors;
    },
    translateError: function (attribute, validatorConfig) {
      let type = _.get(validatorConfig, "type");
      let result = "";
      if (type) {
        validatorConfig.attribute = _.get(
          this.attributeLabels,
          attribute,
          attribute
        );
        result = this.$t(`defaultErrors.${type}`, validatorConfig);
      }
      return result;
    },
  },
};
