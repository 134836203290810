var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-card',[(_vm.item)?_c('v-card-title',{staticClass:"headline primary lighten-1 white--text justify-space-between"},[_vm._v(" ID "+_vm._s(_vm.item.id)+" "),_c('v-btn',{attrs:{"icon":"","color":"white"},on:{"click":function($event){return _vm.$emit('close')}}},[_c('v-icon',[_vm._v("mdi-close")])],1)],1):_vm._e(),_c('v-card-text',[(_vm.dataLoading)?_c('div',{staticClass:"d-flex justify-center pa-10"},[_c('v-progress-circular',{attrs:{"size":50,"color":"primary","indeterminate":""}})],1):_vm._e(),(!_vm.dataLoading)?_c('div',{staticClass:"pt-3"},[_c('v-row',[_c('v-col',[_c('v-list-item',{attrs:{"two-line":""}},[_c('v-list-item-content',[_c('v-list-item-title',[_vm._v("Address:")]),_c('v-list-item-subtitle',[_vm._v(" "+_vm._s(_vm.itemDetails.address)+" ")])],1)],1),(_vm.itemDetails.proxyPackage)?_c('v-list-item',{attrs:{"two-line":""}},[_c('v-list-item-content',[_c('v-list-item-title',[_vm._v("Package:")]),_c('v-list-item-subtitle',[_vm._v(" "+_vm._s(_vm.itemDetails.proxyPackage.title)+" ")])],1)],1):_vm._e()],1),_c('v-col',[_c('v-list-item',{attrs:{"two-line":""}},[_c('v-list-item-content',[_c('v-list-item-title',[_vm._v("Statistics:")]),_c('v-list-item-subtitle',_vm._l((_vm.itemDetails.statistics),function(statisticItem,i){return _c('div',{key:i,staticClass:"pa-2 d-inline-block"},[_c('router-link',{staticClass:"text-decoration-none grey--text text--darken-4",attrs:{"to":{
                      name: 'AccountList',
                      query: {
                        proxy: _vm.proxy.address,
                        target: [statisticItem.name],
                        selectedFilters: ['proxy', 'target'],
                      },
                    }}},[_c('v-icon',{domProps:{"textContent":_vm._s(
                        'mdi-' +
                        (statisticItem.name === 'vkontakte'
                          ? 'alpha-b-box'
                          : statisticItem.name)
                      )}}),_c('span',{staticClass:"pl-1",domProps:{"textContent":_vm._s(statisticItem.value)}})],1)],1)}),0)],1)],1)],1)],1),_c('v-row',[_c('v-col',[_c('v-list-item',[_c('v-list-item-content',[_c('v-list-item-title',[_vm._v(" Use proxy: "),_c('v-chip',{staticClass:"ma-2",attrs:{"color":_vm.item.useProxy ? 'green' : 'indigo',"outlined":"","small":"","dark":""}},[_vm._v(" "+_vm._s(_vm.item.useProxy ? _vm.$t("yes") : _vm.$t("no"))+" ")])],1)],1)],1)],1),_c('v-col',[_c('v-list-item',[_c('v-list-item-content',[_c('v-list-item-title',[_vm._v(" Enabled: "),_c('v-chip',{staticClass:"ma-2",attrs:{"color":_vm.item.enabled ? 'green' : 'red',"outlined":"","small":"","dark":""}},[_vm._v(" "+_vm._s(_vm.item.enabled ? _vm.$t("enabled") : _vm.$t("disabled"))+" ")])],1)],1)],1)],1)],1),_c('v-divider'),_c('v-row',[_c('v-col',[_c('v-list-item',[_c('v-list-item-content',[_c('v-list-item-title',[_vm._v(" Status: "),_c('v-chip',{staticClass:"ma-2",attrs:{"color":_vm.getStatusColor(_vm.itemDetails.status),"outlined":"","small":"","dark":""}},[_vm._v(" "+_vm._s(_vm.itemDetails.status)+" ")])],1)],1)],1),_c('v-list-item',{attrs:{"two-line":""}},[_c('v-list-item-content',[_c('v-list-item-title',[_vm._v("Status changed at:")]),_c('v-list-item-subtitle',[_vm._v(" "+_vm._s(_vm.itemDetails.statusChangedAt)+" ")])],1)],1)],1),_c('v-col',[_c('v-list-item',{attrs:{"two-line":""}},[_c('v-list-item-content',[_c('v-list-item-title',[_vm._v("Created at:")]),_c('v-list-item-subtitle',[_vm._v(" "+_vm._s(_vm.itemDetails.createdAt)+" ")])],1)],1),_c('v-list-item',{attrs:{"two-line":""}},[_c('v-list-item-content',[_c('v-list-item-title',[_vm._v("Updated at:")]),_c('v-list-item-subtitle',[_vm._v(" "+_vm._s(_vm.itemDetails.updatedAt)+" ")])],1)],1)],1)],1)],1):_vm._e()]),_c('v-divider')],1)}
var staticRenderFns = []

export { render, staticRenderFns }