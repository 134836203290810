<template>
  <v-card>
    <v-card-title
      class="headline primary lighten-1 white--text justify-space-between"
    >
      {{ $t("filters") }}
      <v-btn icon color="white" @click="$emit('close')">
        <v-icon>mdi-close</v-icon>
      </v-btn>
    </v-card-title>
    <v-card-text class="mt-2">
      <v-row>
        <v-col cols="6">
          <v-text-field
            name="title"
            :label="attributeLabels.address"
            v-model="search.address"
            type="text"
          ></v-text-field>
        </v-col>
        <v-col cols="3">
          <v-select
            :clearable="true"
            :items="availableStatuses"
            :label="attributeLabels.status"
            v-model="search.status"
          ></v-select>
        </v-col>
        <v-col cols="3">
          <v-select
            :clearable="true"
            item-text="name"
            item-value="value"
            :items="switchers"
            :label="attributeLabels.enabled"
            v-model="search.enabled"
          ></v-select>
        </v-col>
        <v-col cols="3">
          <v-autocomplete
            :clearable="true"
            v-model="search.proxyPackageId"
            :items="proxyPackages"
            :loading="isPackageLoading"
            :search-input.sync="packageSearch"
            hide-no-data
            hide-selected
            item-text="title"
            item-value="id"
            :label="attributeLabels.proxyPackageId"
            :placeholder="$t('startTyping')"
          ></v-autocomplete>
        </v-col>
        <v-col cols="3">
          <v-autocomplete
            :clearable="true"
            :items="proxyPackageSources"
            item-text="source"
            item-value="source"
            :loading="isPackageSourceLoading"
            :search-input.sync="packageSourceSearch"
            :label="attributeLabels.proxyPackageSource"
            v-model="search.proxyPackageSource"
            hide-no-data
            hide-selected
            :placeholder="$t('startTyping')"
          ></v-autocomplete>
        </v-col>
        <v-col cols="2">
          <v-select
            :clearable="true"
            item-text="name"
            item-value="value"
            :items="switchers"
            :label="attributeLabels.useProxy"
            v-model="search.useProxy"
          ></v-select>
        </v-col>
      </v-row>
    </v-card-text>
    <v-card-actions>
      <v-spacer></v-spacer>
      <v-btn color="grey" text @click="$emit('close')">Close</v-btn>
      <v-btn color="blue darken-1" text @click="submit">Apply filters</v-btn>
    </v-card-actions>
  </v-card>
</template>

<script>
import { mapGetters } from "vuex";
import { emptyFilters } from "@/store/modules/proxy/state";
import { FETCH_META, FETCH_SOURCE_META } from "@/store/actions/proxy-package";

const emptyFiltersObj = {};
Object.assign(emptyFiltersObj, emptyFilters);

export default {
  name: "ProxySearch",
  props: {
    labels: Object,
  },
  data() {
    return {
      attributeLabels: this.labels,
      modal: false,
      isSourceLoading: false,
      search: {},
      //Filter values status
      isPackageLoading: false,
      isPackageSourceLoading: false,
      //Filter values
      proxyPackages: [],
      proxyPackageSources: [],
      //Filter entered value
      packageSearch: "",
      packageSourceSearch: "",
      switchers: [
        {
          name: this.$t("yes"),
          value: 1,
        },
        {
          name: this.$t("no"),
          value: 0,
        },
      ],
    };
  },
  computed: {
    ...mapGetters("proxy", ["filters", "availableStatuses"]),
  },
  watch: {
    packageSearch: function (value) {
      this.isPackageLoading = true;
      this.$store
        .dispatch("proxyPackage/" + FETCH_META, { title: value })
        .then(
          (payload) => {
            this.isPackageLoading = false;
            this.proxyPackages = payload.data;
          },
          (err) => {
            console.log("Err", err);
          }
        )
        .catch(() => {});
    },
    packageSourceSearch: function (value) {
      this.isPackageSourceLoading = true;
      this.$store
        .dispatch("proxyPackage/" + FETCH_SOURCE_META, { source: value })
        .then(
          (payload) => {
            this.isPackageSourceLoading = false;
            this.proxyPackageSources = payload.data;
          },
          (err) => {
            console.log("Err", err);
          }
        )
        .catch(() => {});
    },
  },
  methods: {
    submit: function () {
      let filters = {};
      Object.assign(filters, this.search);
      this.$emit("applyFilters", { filters });
    },
  },
  created() {
    Object.assign(this.search, this.filters);
  },
};
</script>
