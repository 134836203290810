<template>
  <v-card>
    <v-card-title
      v-if="item"
      class="headline primary lighten-1 white--text justify-space-between"
    >
      ID {{ item.id }}
      <v-btn icon color="white" @click="$emit('close')">
        <v-icon>mdi-close</v-icon>
      </v-btn>
    </v-card-title>
    <v-card-text>
      <div class="d-flex justify-center pa-10" v-if="dataLoading">
        <v-progress-circular
          :size="50"
          color="primary"
          indeterminate
        ></v-progress-circular>
      </div>
      <div v-if="!dataLoading" class="pa-3 pt-4">
        <v-row>
          <v-col>
            <v-text-field
              name="title"
              :label="$t('proxies.attribute.address')"
              :error-messages="proxyErrors.address"
              v-model="proxy.address"
              type="text"
            ></v-text-field>
          </v-col>
          <v-col>
            <v-autocomplete
              v-model="proxy.proxyPackageId"
              :items="proxyPackages"
              :loading="isPackageLoading"
              :search-input.sync="packageSearch"
              hide-no-data
              hide-selected
              item-text="title"
              item-value="id"
              :label="$t('proxies.attribute.proxyPackage')"
              :placeholder="$t('startTyping')"
            ></v-autocomplete>
          </v-col>
        </v-row>
      </div>
    </v-card-text>
    <v-card-actions>
      <v-spacer></v-spacer>
      <v-btn color="grey" text @click="$emit('close')">Close</v-btn>
      <v-btn color="blue darken-1" text @click="updateItem">Save</v-btn>
    </v-card-actions>
  </v-card>
</template>

<script>
import _ from "lodash";

import { FETCH_PROXY, SAVE_PROXY } from "@/store/actions/proxy";
import { FETCH_META } from "@/store/actions/proxy-package";

import { required, maxLength } from "vuelidate/lib/validators";
import validator from "@/mixin/validator";

const defaultValues = {
  address: "",
  proxyPackageId: "",
  enabled: 1,
  useProxy: 0,
};

export default {
  name: "ProxyForm",
  mixins: [validator],
  prop: {
    create: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      modal: 0,
      dataLoading: true,
      attributeLabels: {
        address: this.$t("proxies.attribute.address"),
        proxyPackageId: this.$t("proxies.attribute.proxyPackage"),
      },
      isPackageLoading: false,
      packageSearch: "",
      proxyPackages: [],
    };
  },
  props: {
    initialItem: Object,
  },
  computed: {
    item: function () {
      return this.initialItem;
    },
    itemDetails: function () {
      return this.item
        ? _.get(
            this.$store.getters["proxy/itemsDetails"],
            "ID_" + this.item.id,
            defaultValues
          )
        : defaultValues;
    },
    proxy: {
      get() {
        return this.itemDetails;
      },
      set: function (value) {
        this.$store.commit("proxy/" + FETCH_PROXY, value);
      },
    },
    proxyErrors: function () {
      return _.merge(
        {
          address: "",
          proxyPackageId: "",
        },
        this.validator.errors.proxy
      );
    },
  },
  watch: {
    item: {
      handler: function () {
        if (this.item) {
          this.loadItem();
        }
      },
      deep: true,
    },
    packageSearch: function (value) {
      this.isPackageLoading = true;
      this.$store
        .dispatch("proxyPackage/" + FETCH_META, { title: value })
        .then(
          (payload) => {
            this.isPackageLoading = false;
            this.proxyPackages = payload.data.map(function (el) {
              return {
                title: el.title,
                id: parseInt(el.id),
              };
            });
          },
          (err) => {
            console.log("Err", err);
          }
        )
        .catch(() => {});
    },
  },
  methods: {
    loadItem: function () {
      this.dataLoading = true;
      const { item } = this;
      this.$store
        .dispatch("proxy/" + FETCH_PROXY, { item })
        .then(
          () => {
            this.dataLoading = false;
          },
          (err) => {
            console.log("Err", err);
          }
        )
        .catch(() => {});
    },
    updateItem: function () {
      if (this.validate()) {
        this.dataLoading = true;
        const { proxy, create } = this;
        this.$store
          .dispatch("proxy/" + SAVE_PROXY, { proxy, create })
          .then(
            (payload) => {
              this.dataLoading = false;
              if (payload.status) {
                this.$emit("submitted");
              }
            },
            (err) => {
              console.log("Err", err);
            }
          )
          .catch(() => {});
      }
    },
  },
  mounted() {
    this.dataLoading = true;
    this.loadItem();
  },
  validations: {
    proxy: {
      address: {
        required,
        maxLength: maxLength(255),
      },
    },
  },
};
</script>
