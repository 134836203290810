<template>
  <v-card>
    <v-card-title
      v-if="item"
      class="headline primary lighten-1 white--text justify-space-between"
    >
      ID {{ item.id }}
      <v-btn icon color="white" @click="$emit('close')">
        <v-icon>mdi-close</v-icon>
      </v-btn>
    </v-card-title>
    <v-card-text>
      <div class="d-flex justify-center pa-10" v-if="dataLoading">
        <v-progress-circular
          :size="50"
          color="primary"
          indeterminate
        ></v-progress-circular>
      </div>
      <div v-if="!dataLoading" class="pt-3">
        <v-row>
          <v-col>
            <v-list-item two-line>
              <v-list-item-content>
                <v-list-item-title>Address:</v-list-item-title>
                <v-list-item-subtitle>
                  {{ itemDetails.address }}
                </v-list-item-subtitle>
              </v-list-item-content>
            </v-list-item>
            <v-list-item two-line v-if="itemDetails.proxyPackage">
              <v-list-item-content>
                <v-list-item-title>Package:</v-list-item-title>
                <v-list-item-subtitle>
                  {{ itemDetails.proxyPackage.title }}
                </v-list-item-subtitle>
              </v-list-item-content>
            </v-list-item>
          </v-col>
          <v-col>
            <v-list-item two-line>
              <v-list-item-content>
                <v-list-item-title>Statistics:</v-list-item-title>
                <v-list-item-subtitle>
                  <div
                    v-for="(statisticItem, i) in itemDetails.statistics"
                    :key="i"
                    class="pa-2 d-inline-block"
                  >
                    <router-link
                      :to="{
                        name: 'AccountList',
                        query: {
                          proxy: proxy.address,
                          target: [statisticItem.name],
                          selectedFilters: ['proxy', 'target'],
                        },
                      }"
                      class="text-decoration-none grey--text text--darken-4"
                    >
                      <v-icon
                        v-text="
                          'mdi-' +
                          (statisticItem.name === 'vkontakte'
                            ? 'alpha-b-box'
                            : statisticItem.name)
                        "
                      ></v-icon>
                      <span v-text="statisticItem.value" class="pl-1"></span>
                    </router-link>
                  </div>
                </v-list-item-subtitle>
              </v-list-item-content>
            </v-list-item>
          </v-col>
        </v-row>
        <v-row>
          <v-col>
            <v-list-item>
              <v-list-item-content>
                <v-list-item-title>
                  Use proxy:
                  <v-chip
                    class="ma-2"
                    :color="item.useProxy ? 'green' : 'indigo'"
                    outlined
                    small
                    dark
                  >
                    {{ item.useProxy ? $t("yes") : $t("no") }}
                  </v-chip>
                </v-list-item-title>
              </v-list-item-content>
            </v-list-item>
          </v-col>
          <v-col>
            <v-list-item>
              <v-list-item-content>
                <v-list-item-title>
                  Enabled:
                  <v-chip
                    class="ma-2"
                    :color="item.enabled ? 'green' : 'red'"
                    outlined
                    small
                    dark
                  >
                    {{ item.enabled ? $t("enabled") : $t("disabled") }}
                  </v-chip>
                </v-list-item-title>
              </v-list-item-content>
            </v-list-item>
          </v-col>
        </v-row>
        <v-divider></v-divider>
        <v-row>
          <v-col>
            <v-list-item>
              <v-list-item-content>
                <v-list-item-title>
                  Status:
                  <v-chip
                    class="ma-2"
                    :color="getStatusColor(itemDetails.status)"
                    outlined
                    small
                    dark
                  >
                    {{ itemDetails.status }}
                  </v-chip>
                </v-list-item-title>
              </v-list-item-content>
            </v-list-item>
            <v-list-item two-line>
              <v-list-item-content>
                <v-list-item-title>Status changed at:</v-list-item-title>
                <v-list-item-subtitle>
                  {{ itemDetails.statusChangedAt }}
                </v-list-item-subtitle>
              </v-list-item-content>
            </v-list-item>
          </v-col>
          <v-col>
            <v-list-item two-line>
              <v-list-item-content>
                <v-list-item-title>Created at:</v-list-item-title>
                <v-list-item-subtitle>
                  {{ itemDetails.createdAt }}
                </v-list-item-subtitle>
              </v-list-item-content>
            </v-list-item>
            <v-list-item two-line>
              <v-list-item-content>
                <v-list-item-title>Updated at:</v-list-item-title>
                <v-list-item-subtitle>
                  {{ itemDetails.updatedAt }}
                </v-list-item-subtitle>
              </v-list-item-content>
            </v-list-item>
          </v-col>
        </v-row>
      </div>
    </v-card-text>
    <v-divider></v-divider>
  </v-card>
</template>

<script>
import { FETCH_PROXY } from "@/store/actions/proxy";

import _ from "lodash";

export default {
  name: "ProxyView",
  data() {
    return {
      dataLoading: true,
    };
  },
  props: {
    initialItem: Object,
  },
  computed: {
    itemDetails: function () {
      return this.$store.getters["proxy/getItemDetails"]({
        key: "ID_" + this.item.id,
        defaultValue: this.item,
      });
    },
    itemLoaded: function () {
      return !_.isEmpty(this.itemDetails);
    },
    item: function () {
      return this.initialItem;
    },
  },
  watch: {
    item: {
      handler: function () {
        this.loadItem();
      },
      deep: true,
    },
  },
  methods: {
    loadItem: function () {
      this.dataLoading = true;
      const { item } = this;
      this.$store
        .dispatch("proxy/" + FETCH_PROXY, { item })
        .then(
          () => {
            this.dataLoading = false;
          },
          (err) => {
            console.log("Err", err);
          }
        )
        .catch(() => {});
    },
    getStatusColor: function (status) {
      return this.$store.getters["proxy/getColorByStatus"]({
        status,
        defaultValue: "default",
      });
    },
  },
  mounted() {
    this.loadItem();
  },
};
</script>
