<template>
  <div class="about">
    <v-row>
      <v-col>
        <h1>{{ $t("proxies.title") }}</h1>
      </v-col>
      <v-col class="text-end">
        <v-btn small color="primary" @click="confirmSync(null)">
          {{ $t("proxies.syncAll") }}
        </v-btn>
      </v-col>
    </v-row>
    <div class="d-flex justify-space-between">
      <div class="pt-4 font-weight-bold">
        <v-btn text large color="primary" @click="filterDialog = true">
          {{ $t("filters") }}
          <v-icon small class="ml-2">mdi-filter-menu-outline</v-icon>
        </v-btn>
        <v-btn
          v-if="hasAppliedFilters"
          outlined
          x-small
          color="red"
          class="ml-2"
          @click="resetFilters"
        >
          {{ $t("resetFilters") }}
        </v-btn>
      </div>
      <div class="d-flex">
        <v-select
          item-text="label"
          item-value="attribute"
          :items="attributes"
          label="Order by"
          v-model="sort.sortBy"
          style="max-width: 180px"
        ></v-select>
        <span v-if="sort.sortBy" class="mt-5 ml-2">
          <v-icon v-if="sort.desc" @click="sort.desc = false">
            mdi-sort-descending
          </v-icon>
          <v-icon v-if="!sort.desc" @click="sort.desc = true">
            mdi-sort-ascending
          </v-icon>
        </span>
      </div>
    </div>
    <div v-if="hasAppliedFilters">
      <v-chip
        close
        color="primary lighten-1"
        v-for="(filter, label) in formattedFilters"
        :key="label"
        class="mr-1 mb-2"
        @click:close="resetFilter(label)"
      >
        {{ attributeLabels[label] }}: {{ prepareAttributeValue(label, filter) }}
      </v-chip>
    </div>
    <div class="text-right">
      <span v-if="totalCount > 0" class="ml-2 body-2"
        >Showing {{ offset + 1 }}-{{ maxItem }} of {{ totalCount }} items</span
      >
    </div>
    <div>
      <v-overlay
        :absolute="true"
        :value="dataLoading"
        justify-center
        align-center
        opacity="0.15"
      >
        <v-progress-circular size="64" indeterminate></v-progress-circular>
      </v-overlay>
      <div v-if="!dataLoading">
        <!--    Data table     -->
        <v-data-iterator
          hide-default-footer
          disable-filtering
          disable-pagination
          disable-sort
          :items="items"
        >
          <template v-slot:header>
            <div class="d-sm-none d-md-none d-lg-block px-4 py-0 pb-0">
              <v-row class="grey--text body-2" no-gutters>
                <v-col lg="3">{{ $t("proxies.attribute.address") }}</v-col>
                <v-col lg="2" class="text-center">{{
                  $t("proxies.attribute.status")
                }}</v-col>
                <v-col lg="1">{{ $t("proxies.attribute.enabled") }}</v-col>
                <v-col lg="1">{{ $t("proxies.attribute.useProxy") }}</v-col>
                <v-col lg="1">{{
                  $t("proxies.attribute.project-statistics")
                }}</v-col>
                <v-col lg="1">{{ $t("proxies.attribute.proxyPackage") }}</v-col>
                <v-col lg="2">{{
                  $t("proxies.attribute.proxyPackageSource")
                }}</v-col>
              </v-row>
            </div>
          </template>
          <template v-slot:default="props">
            <proxy-item
              v-for="(item, i) in props.items"
              :key="i"
              :item="item"
              @editInfo="editItem(item)"
              @viewInfo="viewItem(item)"
              @syncItem="confirmSync(item)"
            ></proxy-item>
          </template>
        </v-data-iterator>
        <div class="text-right">
          <span v-if="totalCount > 0" class="ml-2 body-2"
            >Showing {{ offset + 1 }}-{{ maxItem }} of
            {{ totalCount }} items</span
          >
        </div>
      </div>
    </div>
    <!--    Pagination    -->
    <div v-if="pageCount > 1" class="text-center pt-6">
      <v-pagination
        v-model="pagination.page"
        :length="pageCount"
        :total-visible="9"
      ></v-pagination>
    </div>
    <!--    Wrapper for  edit dialog   -->
    <v-dialog v-model="filterDialog" max-width="900px">
      <proxy-search
        v-if="filterDialog"
        :labels="attributeLabels"
        @close="filterDialog = false"
        @applyFilters="applyFilters"
        :package="selectedProxyPackage"
      ></proxy-search>
    </v-dialog>
    <!--    Wrapper for  edit dialog   -->

    <v-dialog v-model="editDialog" max-width="650px">
      <proxy-form
        :initialItem="selectedItem"
        @close="closeEditDialog"
        @submitted="
          loadItems();
          closeEditDialog();
        "
      ></proxy-form>
    </v-dialog>
    <v-dialog v-model="viewDialog" max-width="800px">
      <proxy-view
        :initialItem="selectedItem"
        @close="closeViewDialog"
      ></proxy-view>
    </v-dialog>
    <v-dialog v-model="confirmDialog" max-width="450px">
      <v-card>
        <v-card-title
          class="headline primary lighten-1 white--text justify-space-between"
        >
          Sync confirmation
          <v-btn icon color="white" @click="confirmDialog = false">
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-card-title>
        <v-card-text v-if="selectedItem" class="mt-2">
          Are you sure you want to sync {{ selectedItem.address }}?
        </v-card-text>
        <v-card-text v-if="!selectedItem" class="mt-2">
          Are you sure you want to sync all proxies?
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="grey" text @click="confirmDialog = false">Close</v-btn>
          <v-btn
            text
            color="blue darken-1"
            @click="selectedItem ? syncItem(selectedItem) : syncAll()"
          >
            Confirm
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import _ from "lodash";

import {
  FETCH_PROXIES,
  SYNC_PROXIES,
  SYNC_PROXY,
  SET_FILTER,
} from "@/store/actions/proxy";
import { emptyFilters } from "@/store/modules/proxy/state";

import ProxyView from "@/views/proxy/ProxyView";
import ProxyForm from "@/views/proxy/ProxyForm";

import ProxySearch from "@/views/proxy/list/ProxySearch";
import ProxyItem from "@/views/proxy/list/ProxyItem";
import { mapGetters, mapState } from "vuex";
import { FETCH_PACKAGE } from "@/store/actions/proxy-package";

export default {
  name: "ProxyList",
  components: { ProxyView, ProxyForm, ProxySearch, ProxyItem },
  data() {
    return {
      modal: false,
      editDialog: false,
      viewDialog: false,
      filterDialog: false,
      confirmDialog: false,
      dataLoading: true,
      selectedItem: null,
      attributes: [
        {
          label: this.$t("proxies.attribute.status"),
          attribute: "status",
        },
        {
          label: this.$t("proxies.attribute.enabled"),
          attribute: "enabled",
        },
        {
          label: this.$t("proxies.attribute.proxyPackage"),
          attribute: "proxyPackageId",
        },
        {
          label: this.$t("proxies.attribute.statusChangedAt"),
          attribute: "statusChangedAt",
        },
        {
          label: this.$t("proxies.attribute.updatedAt"),
          attribute: "updatedAt",
        },
        {
          label: this.$t("proxies.attribute.createdAt"),
          attribute: "createdAt",
        },
      ],
      attributeLabels: {
        address: this.$t("proxies.attribute.address"),
        status: this.$t("proxies.attribute.status"),
        enabled: this.$t("proxies.attribute.enabled"),
        useProxy: this.$t("proxies.attribute.useProxy"),
        proxyPackage: this.$t("proxies.attribute.proxyPackage"),
        proxyPackageSource: this.$t("proxies.attribute.proxyPackageSource"),
        source: this.$t("proxies.attribute.proxyPackageSource"),
        statistics: this.$t("proxies.attribute.project-statistics"),
        proxyPackageId: this.$t("proxies.attribute.proxyPackageId"),
      },
      selectedProxyPackage: null,
    };
  },
  computed: {
    ...mapState("proxy", ["pagination", "filters", "sort"]),
    ...mapGetters("proxy", [
      "offset",
      "itemsPerPage",
      "totalCount",
      "items",
      "pageCount",
      "hasAppliedFilters",
      "formattedFilters",
      "filters",
    ]),
    maxItem: function () {
      return Math.min(this.totalCount, this.offset + this.itemsPerPage);
    },
  },
  watch: {
    sort: {
      handler: function () {
        this.loadItems();
      },
      deep: true,
    },
    pagination: {
      handler: function () {
        this.loadItems();
      },
      deep: true,
    },
    filters: {
      handler: function () {
        this.$router.push({
          name: "ProxyList",
          query: this.formattedFilters,
        });
        this.loadItems();
      },
      deep: true,
    },
  },
  methods: {
    resetFilters: function () {
      this.$store.commit("proxy/" + SET_FILTER, this.emptyFilters);
    },
    resetFilter: function (filterName) {
      let newFilters = _.merge({}, this.filters);
      newFilters[filterName] = _.get(emptyFilters, filterName, null);
      this.$store.commit("proxy/" + SET_FILTER, newFilters);
    },
    applyFilters: function (data) {
      this.$store.commit("proxy/" + SET_FILTER, data.filters);
      this.filterDialog = false;
    },
    editItem: function (item) {
      this.selectedItem = item;
      this.editDialog = true;
    },
    viewItem: function (item) {
      this.selectedItem = item;
      this.viewDialog = true;
    },
    closeViewDialog: function () {
      this.selectedItem = null;
      this.viewDialog = false;
    },
    closeEditDialog: function () {
      this.selectedItem = null;
      this.editDialog = false;
    },
    confirmSync: function (item) {
      this.selectedItem = item;
      this.confirmDialog = true;
    },
    syncItem: function (proxy) {
      this.confirmDialog = false;
      this.$store
        .dispatch("proxy/" + SYNC_PROXY, { proxy })
        .then(
          (response) => {
            if (response.status === true) {
              this.$notify({
                group: "messages",
                title: "Sync proxy",
                text: "Synchronization task successfully submitted",
              });
            }
          },
          (err) => {
            console.log("Err", err);
          }
        )
        .catch(() => {});
    },
    syncAll: function () {
      this.confirmDialog = false;
      this.$store
        .dispatch("proxy/" + SYNC_PROXIES)
        .then(
          (response) => {
            if (response.status === true) {
              this.$notify({
                group: "messages",
                title: "Sync all proxies",
                text: "Synchronization task successfully submitted",
              });
            }
          },
          (err) => {
            console.log("Err", err);
          }
        )
        .catch(() => {});
    },
    loadItems: function () {
      this.dataLoading = true;
      const { page, sort, filters } = this;
      this.$store
        .dispatch("proxy/" + FETCH_PROXIES, { sort, page, filters })
        .then(
          () => {
            this.dataLoading = false;
          },
          (err) => {
            console.log("Err", err);
          }
        )
        .catch(() => {});
    },
    prepareAttributeValue: function (attribute, value) {
      let result = value;
      const component = this;
      if (_.isArray(value)) {
        return value
          .map(function (itemValue) {
            return component.prepareAttributeValue(attribute, itemValue);
          })
          .join(", ");
      }
      switch (attribute) {
        case "proxyPackageId": {
          result = this.selectedProxyPackage;
          this.$store
            .dispatch("proxyPackage/" + FETCH_PACKAGE, { item: { id: value } })
            .then(
              (payload) => {
                if (payload.status) {
                  this.selectedProxyPackage = payload.data.name;
                }
              },
              (err) => {
                console.log("Err", err);
              }
            )
            .catch(() => {});
          break;
        }
        case "useProxy": {
          result = value ? this.$t("yes") : this.$t("no");
          break;
        }
        case "enabled": {
          result = value ? this.$t("yes") : this.$t("no");
          break;
        }
      }
      return result;
    },
  },
  mounted() {
    if (!_.isEmpty(this.$route.query)) {
      let filters = {};
      _.forIn(this.$route.query, function (value, key) {
        filters[key] = value;
      });
      this.$store.commit(
        "proxy/" + SET_FILTER,
        _.merge({}, this.emptyFilters, filters)
      );
    }
    this.loadItems();
  },
};
</script>
