<template>
  <v-sheet outlined class="mt-1">
    <v-card-text class="pa-2 pl-4">
      <v-row no-gutters>
        <v-col sm="12" md="6" lg="3" class="d-flex flex-nowrap">
          <div class="d-inline-block text-truncate" style="max-width: 100%">
            {{ proxy.address }}
          </div>
        </v-col>
        <v-col sm="3" md="2" lg="2" class="text-center">
          <v-chip :color="getColorByStatus(proxy)" outlined small dark>
            {{ proxy.status }}
          </v-chip>
          <div class="caption">{{ proxy.statusChangedAt }}</div>
        </v-col>
        <v-col sm="3" md="2" lg="1">
          <v-chip :color="proxy.enabled ? 'green' : 'red'" outlined small dark>
            {{ proxy.enabled ? $t("enabled") : $t("disabled") }}
          </v-chip>
        </v-col>
        <v-col sm="3" md="2" lg="1">
          <v-chip
            :color="proxy.useProxy ? 'green' : 'indigo'"
            outlined
            small
            dark
            class="d-sm-none d-md-none d-lg-inline-flex"
          >
            {{ proxy.useProxy ? $t("yes") : $t("no") }}
          </v-chip>
          <v-chip
            v-if="proxy.useProxy"
            :color="proxy.useProxy ? 'green' : 'indigo'"
            outlined
            small
            dark
            class="d-lg-none"
          >
            {{ $t("proxies.attribute.useProxy") }}
          </v-chip>
        </v-col>
        <v-col sm="3" md="3" lg="1">
          <div v-for="(statisticItem, i) in proxy.statistics" :key="i">
            <router-link
              :to="{
                name: 'AccountList',
                query: {
                  proxy: proxy.address,
                  target: [statisticItem.name],
                  selectedFilters: ['proxy', 'target'],
                },
              }"
              class="text-decoration-none grey--text text--darken-4"
            >
              <v-icon
                v-text="
                  'mdi-' +
                  (statisticItem.name === 'vkontakte'
                    ? 'alpha-b-box'
                    : statisticItem.name)
                "
              ></v-icon>
              <span v-text="statisticItem.value" class="pl-1"></span>
            </router-link>
          </div>
        </v-col>
        <v-col sm="5" md="3" lg="1">
          <router-link
            v-if="proxy.proxyPackage"
            :to="{
              name: 'ProxyPackageList',
              query: { id: proxy.proxyPackage.id },
            }"
            class="pr-1 d-inline-block text-truncate"
            style="max-width: 100%"
          >
            {{ proxy.proxyPackage.title }}
          </router-link>
          <span v-if="!proxy.proxyPackage" class="d-lg-none grey--text caption">
            Proxy package not set
          </span>
        </v-col>
        <v-col sm="4" md="3" lg="2">
          <div v-if="proxy.proxyPackage">
            {{ proxy.proxyPackage.source }}
            <span
              v-if="!proxy.proxyPackage.source"
              class="d-lg-none grey--text caption"
            >
              Proxy source not set
            </span>
          </div>
        </v-col>
        <v-col sm="3" md="3" lg="1" class="text-end pr-2">
          <v-btn x-small icon color="primary" @click="$emit('editInfo')">
            <v-icon>mdi-pencil-outline</v-icon>
          </v-btn>
          <v-btn x-small icon color="primary" @click="$emit('viewInfo')">
            <v-icon>mdi-eye-outline</v-icon>
          </v-btn>
          <v-btn x-small icon color="primary" @click="$emit('syncItem')">
            <v-icon>mdi-sync</v-icon>
          </v-btn>
        </v-col>
      </v-row>
    </v-card-text>
  </v-sheet>
</template>

<script>
import { mapGetters } from "vuex";

export default {
  name: "ProxyItem",
  props: {
    item: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      proxy: this.item,
    };
  },
  computed: {
    ...mapGetters("proxy", ["getColorByStatus"]),
  },
  methods: {},
};
</script>
