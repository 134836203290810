var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-sheet',{staticClass:"mt-1",attrs:{"outlined":""}},[_c('v-card-text',{staticClass:"pa-2 pl-4"},[_c('v-row',{attrs:{"no-gutters":""}},[_c('v-col',{staticClass:"d-flex flex-nowrap",attrs:{"sm":"12","md":"6","lg":"3"}},[_c('div',{staticClass:"d-inline-block text-truncate",staticStyle:{"max-width":"100%"}},[_vm._v(" "+_vm._s(_vm.proxy.address)+" ")])]),_c('v-col',{staticClass:"text-center",attrs:{"sm":"3","md":"2","lg":"2"}},[_c('v-chip',{attrs:{"color":_vm.getColorByStatus(_vm.proxy),"outlined":"","small":"","dark":""}},[_vm._v(" "+_vm._s(_vm.proxy.status)+" ")]),_c('div',{staticClass:"caption"},[_vm._v(_vm._s(_vm.proxy.statusChangedAt))])],1),_c('v-col',{attrs:{"sm":"3","md":"2","lg":"1"}},[_c('v-chip',{attrs:{"color":_vm.proxy.enabled ? 'green' : 'red',"outlined":"","small":"","dark":""}},[_vm._v(" "+_vm._s(_vm.proxy.enabled ? _vm.$t("enabled") : _vm.$t("disabled"))+" ")])],1),_c('v-col',{attrs:{"sm":"3","md":"2","lg":"1"}},[_c('v-chip',{staticClass:"d-sm-none d-md-none d-lg-inline-flex",attrs:{"color":_vm.proxy.useProxy ? 'green' : 'indigo',"outlined":"","small":"","dark":""}},[_vm._v(" "+_vm._s(_vm.proxy.useProxy ? _vm.$t("yes") : _vm.$t("no"))+" ")]),(_vm.proxy.useProxy)?_c('v-chip',{staticClass:"d-lg-none",attrs:{"color":_vm.proxy.useProxy ? 'green' : 'indigo',"outlined":"","small":"","dark":""}},[_vm._v(" "+_vm._s(_vm.$t("proxies.attribute.useProxy"))+" ")]):_vm._e()],1),_c('v-col',{attrs:{"sm":"3","md":"3","lg":"1"}},_vm._l((_vm.proxy.statistics),function(statisticItem,i){return _c('div',{key:i},[_c('router-link',{staticClass:"text-decoration-none grey--text text--darken-4",attrs:{"to":{
              name: 'AccountList',
              query: {
                proxy: _vm.proxy.address,
                target: [statisticItem.name],
                selectedFilters: ['proxy', 'target'],
              },
            }}},[_c('v-icon',{domProps:{"textContent":_vm._s(
                'mdi-' +
                (statisticItem.name === 'vkontakte'
                  ? 'alpha-b-box'
                  : statisticItem.name)
              )}}),_c('span',{staticClass:"pl-1",domProps:{"textContent":_vm._s(statisticItem.value)}})],1)],1)}),0),_c('v-col',{attrs:{"sm":"5","md":"3","lg":"1"}},[(_vm.proxy.proxyPackage)?_c('router-link',{staticClass:"pr-1 d-inline-block text-truncate",staticStyle:{"max-width":"100%"},attrs:{"to":{
            name: 'ProxyPackageList',
            query: { id: _vm.proxy.proxyPackage.id },
          }}},[_vm._v(" "+_vm._s(_vm.proxy.proxyPackage.title)+" ")]):_vm._e(),(!_vm.proxy.proxyPackage)?_c('span',{staticClass:"d-lg-none grey--text caption"},[_vm._v(" Proxy package not set ")]):_vm._e()],1),_c('v-col',{attrs:{"sm":"4","md":"3","lg":"2"}},[(_vm.proxy.proxyPackage)?_c('div',[_vm._v(" "+_vm._s(_vm.proxy.proxyPackage.source)+" "),(!_vm.proxy.proxyPackage.source)?_c('span',{staticClass:"d-lg-none grey--text caption"},[_vm._v(" Proxy source not set ")]):_vm._e()]):_vm._e()]),_c('v-col',{staticClass:"text-end pr-2",attrs:{"sm":"3","md":"3","lg":"1"}},[_c('v-btn',{attrs:{"x-small":"","icon":"","color":"primary"},on:{"click":function($event){return _vm.$emit('editInfo')}}},[_c('v-icon',[_vm._v("mdi-pencil-outline")])],1),_c('v-btn',{attrs:{"x-small":"","icon":"","color":"primary"},on:{"click":function($event){return _vm.$emit('viewInfo')}}},[_c('v-icon',[_vm._v("mdi-eye-outline")])],1),_c('v-btn',{attrs:{"x-small":"","icon":"","color":"primary"},on:{"click":function($event){return _vm.$emit('syncItem')}}},[_c('v-icon',[_vm._v("mdi-sync")])],1)],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }